import { RouteRecordRaw } from 'vue-router'
const Layout = () => import('@/layout/index.vue')
const manager = () => import('@/views/admin/manager/index.vue')

// logger

const queryLogger = () => import('@/views/admin/manager/components/query-logger/index.vue')

// user

const queryUser = () => import('@/views/admin/manager/components/query-user/index.vue')
const queryUserDetail = () => import('@/views/admin/manager/components/query-user/detail/index.vue')

const updateUserName = () => import('@/views/admin/manager/components/query-user/update-name/index.vue')
const updateUserAuthority = () => import('@/views/admin/manager/components/query-user/update-authority/index.vue')
const updateUserPassword = () => import('@/views/admin/manager/components/query-user/update-password/index.vue')

const createUser = () => import('@/views/admin/manager/components/create-user/index.vue')

const managerRouter: RouteRecordRaw = {
  name: 'manager-router',
  component: Layout,
  path: '/',
  meta: {
    title: '人员管理'
  },
  children: [
    {
      path: 'manager',
      name: 'manager',
      component: manager,
      meta: {
        title: '人员管理',
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-logger',
      name: 'manager-query-logger',
      component: queryLogger,
      meta: {
        title: '查看登录日志',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-user',
      name: 'query-user',
      component: queryUser,
      meta: {
        title: '用户列表',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-user/detail/:id',
      name: 'query-user-detail',
      component: queryUserDetail,
      meta: {
        title: '用户详情',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-user/update-name/:id',
      name: 'query-user-update-name',
      component: updateUserName,
      meta: {
        title: '修改用户昵称',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-user/update-authority/:id',
      name: 'query-user-update-authority',
      component: updateUserAuthority,
      meta: {
        title: '修改用户权限',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/query-user/update-password/:id',
      name: 'query-user-update-password',
      component: updateUserPassword,
      meta: {
        title: '修改用户密码',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    },
    {
      path: 'manager/create-user',
      name: 'create-user',
      component: createUser,
      meta: {
        title: '创建用户',
        canLeftArrow: true,
        role: ['ROLE_ADMIN', 'ROLE_SYS_ADMIN']
      }
    }
  ]
}

export default managerRouter
