import { showLoadingToast, closeToast } from 'vant'

export default class loadingController {
  static isLoading (message = '') {
    showLoadingToast({
      message,
      forbidClick: true,
      duration: 15000
    })
  }

  static clearLoading () {
    closeToast()
  }
}
