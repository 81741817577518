import { RouteRecordRaw } from 'vue-router'
const Layout = () => import('@/layout/index.vue')
const home = () => import('@/views/admin/home/index.vue')

// product

const createProduct = () => import('@/views/admin/home/components/product/create-product/index.vue')

const queryProduct = () => import('@/views/admin/home/components/product/query-product/index.vue')
const queryProductDetail = () => import('@/views/admin/home/components/product/query-product/detail/index.vue')

// bound

const createInBound = () => import('@/views/admin/home/components/bound/create-in-bound/index.vue')
const cutterInBound = () => import('@/views/admin/home/components/bound/cutter-in-bound/index.vue')
const createOutBound = () => import('@/views/admin/home/components/bound/create-out-bound/index.vue')
const queryBoundToday = () => import('@/views/admin/home/components/bound/query-bound-today/index.vue')

// statistics

const queryInBound = () => import('@/views/admin/home/components/statistics/query-in-bound/index.vue')
const queryInBoundDetail = () => import('@/views/admin/home/components/statistics/query-in-bound/detail/index.vue')

const queryOutBound = () => import('@/views/admin/home/components/statistics/query-out-bound/index.vue')
const queryOutBoundDetail = () => import('@/views/admin/home/components/statistics/query-out-bound/detail/index.vue')

const queryInventory = () => import('@/views/admin/home/components/statistics/query-inventory/index.vue')
const queryInventoryDetail = () => import('@/views/admin/home/components/statistics/query-inventory/detail/index.vue')

const queryInBoundDaily = () => import('@/views/admin/home/components/statistics/query-in-bound-daily/index.vue')
const queryOutBoundDaily = () => import('@/views/admin/home/components/statistics/query-out-bound-daily/index.vue')

const homeRouter: RouteRecordRaw = {
  name: 'home-router',
  component: Layout,
  path: '/',
  meta: {
    title: '出入库'
  },
  children: [
    {
      path: 'home',
      name: 'home',
      component: home,
      meta: {
        title: '出入库',
        role: []
      }
    },
    {
      path: 'home/create-product',
      name: 'create-product',
      component: createProduct,
      meta: {
        title: '添加货品',
        canLeftArrow: true,
        role: ['ROLE_PRODUCT_EDIT']
      }
    },
    {
      path: 'home/query-product',
      name: 'query-product',
      component: queryProduct,
      meta: {
        title: '货品列表',
        canLeftArrow: true,
        role: ['ROLE_PRODUCT_VIEW']
      }
    },
    {
      path: 'home/query-product/detail/:name/:size',
      name: 'query-product-detail',
      component: queryProductDetail,
      meta: {
        title: '货品详情',
        canLeftArrow: true,
        role: ['ROLE_PRODUCT_VIEW']
      }
    },
    {
      path: 'home/create-in-bound',
      name: 'create-in-bound',
      component: createInBound,
      meta: {
        title: '添加入库',
        canLeftArrow: true,
        role: ['ROLE_INBOUND_EDIT']
      }
    },
    {
      path: 'home/cutter-in-bound',
      name: 'cutter-in-bound',
      component: cutterInBound,
      meta: {
        title: '裁床入库',
        canLeftArrow: true,
        role: ['ROLE_INBOUND_EDIT']
      }
    },
    {
      path: 'home/create-out-bound',
      name: 'create-out-bound',
      component: createOutBound,
      meta: {
        title: '添加出库',
        canLeftArrow: true,
        role: ['ROLE_OUTBOUND_EDIT']
      }
    },
    {
      path: 'home/query-bound-today',
      name: 'query-bound-today',
      component: queryBoundToday,
      meta: {
        title: '今日出入库详情',
        canLeftArrow: true,
        role: ['ROLE_INVENTORY_VIEW']
      }
    },
    {
      path: 'home/query-in-bound',
      name: 'query-in-bound',
      component: queryInBound,
      meta: {
        title: '入库列表',
        canLeftArrow: true,
        role: ['ROLE_INBOUND_VIEW']
      }
    },
    {
      path: 'home/query-in-bound/detail/:id',
      name: 'query-in-bound-detail',
      component: queryInBoundDetail,
      meta: {
        title: '入库详情',
        canLeftArrow: true,
        role: ['ROLE_INBOUND_VIEW']
      }
    },
    {
      path: 'home/query-out-bound',
      name: 'query-out-bound',
      component: queryOutBound,
      meta: {
        title: '出库列表',
        canLeftArrow: true,
        role: ['ROLE_OUTBOUND_VIEW']
      }
    },
    {
      path: 'home/query-out-bound/detail/:id',
      name: 'query-out-bound-detail',
      component: queryOutBoundDetail,
      meta: {
        title: '出库详情',
        canLeftArrow: true,
        role: ['ROLE_OUTBOUND_VIEW']
      }
    },
    {
      path: 'home/query-inventory',
      name: 'query-inventory',
      component: queryInventory,
      meta: {
        title: '库存列表',
        canLeftArrow: true,
        role: ['ROLE_INVENTORY_VIEW']
      }
    },
    {
      path: 'home/query-inventory/detail/:name/:size',
      name: 'query-inventory-detail',
      component: queryInventoryDetail,
      meta: {
        title: '库存详情',
        canLeftArrow: true,
        role: ['ROLE_INVENTORY_VIEW']
      }
    },
    {
      path: 'home/query-inbounds-daily',
      name: 'query-inbounds-daily',
      component: queryInBoundDaily,
      meta: {
        title: '每日入库',
        canLeftArrow: true,
        role: ['ROLE_INBOUND_VIEW']
      }
    },
    {
      path: 'home/query-outbounds-daily',
      name: 'query-outbounds-daily',
      component: queryOutBoundDaily,
      meta: {
        title: '每日出库',
        canLeftArrow: true,
        role: ['ROLE_OUTBOUND_VIEW']
      }
    }
  ]
}

export default homeRouter
