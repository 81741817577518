import { RouteRecordRaw } from 'vue-router'
const Layout = () => import('@/layout/index.vue')
const profile = () => import('@/views/admin/profile/index.vue')

const queryLogger = () => import('@/views/admin/profile/components/query-logger/index.vue')
const updateName = () => import('@/views/admin/profile/components/update-name/index.vue')
const updatePassword = () => import('@/views/admin/profile/components/update-password/index.vue')

const profileRouter: RouteRecordRaw = {
  name: 'profile-router',
  component: Layout,
  path: '/',
  meta: {
    title: '我的'
  },
  children: [
    {
      path: 'profile',
      name: 'profile',
      component: profile,
      meta: {
        title: '我的',
        role: ['ROLE_USER']
      }
    },
    {
      path: 'profile/query-logger',
      name: 'profile-query-logger',
      component: queryLogger,
      meta: {
        title: '登录日志',
        canLeftArrow: true,
        role: ['ROLE_USER']
      }
    },
    {
      path: 'profile/update-name',
      name: 'update-name',
      component: updateName,
      meta: {
        title: '修改昵称',
        canLeftArrow: true,
        type: 'name',
        role: ['ROLE_USER']
      }
    },
    {
      path: 'profile/update-password',
      name: 'update-password',
      component: updatePassword,
      meta: {
        title: '修改密码',
        canLeftArrow: true,
        type: 'password',
        role: ['ROLE_USER']
      }
    }
  ]
}

export default profileRouter
