import type { NotifyOptions } from 'vant'

export default function getErrorMessage (err: any): NotifyOptions {
  if (err.message === 'Network Error') {
    return {
      type: 'warning',
      message: '请求超时'
    }
  }

  const errData = err.response
  switch (errData.status) {
    case 401:
      return {}
    case 404:
      return {
        type: 'warning',
        message: '找不到目标资源'
      }
  }
  // Uncaught exception, so using notification of danger
  return {
    type: 'danger',
    message: `错误描述: ${errData.statusText}, 错误状态码: ${errData.status}`
  }
}
