import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { authorityHttp } from '@/api/authority'
import loadingController from '@/utils/loadingController'

interface UserStore {
  id?: number

  username?: string

  phone?: string

  name?: string

  token?: string

  authorities?: Array<string>
}

class UserStoreModule implements Module<UserStore, any> {
  namespaced = true

  state: UserStore = {
    username: '',
    token: ''
  }

  mutations: MutationTree<UserStore> = {
    setUserId (state, id) {
      state.id = id
    },
    setUsername (state, username) {
      state.username = username
    },
    setPhone (state, phone) {
      state.phone = phone
    },
    setName (state, name) {
      state.name = name
    },
    setToken (state, token) {
      state.token = token
    },
    setAuthorities (state, authorities) {
      state.authorities = authorities
    }
  }

  actions: ActionTree<UserStore, any> = {
    logout ({ commit }) {
      return new Promise((resolve, reject) => {
        authorityHttp.logout()
          .then((response: any) => {
            commit('setUsername', '')
            resolve(response)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    },
    getUserInfo ({ commit }) {
      loadingController.isLoading()
      return new Promise((resolve, reject) => {
        authorityHttp.getUserInfo()
          .then((response: any) => {
            commit('setToken', response.csrf.token)
            commit('setUsername', (!!response.user.username) ? response.user.username : 'anonymousUser')
            if (!!response.user.username) {
              commit('setUserId', response.user.id)
              commit('setName', response.user.name)
              commit('setPhone', response.user.phone)
              commit('setAuthorities', response.user.authorities)
            }
            resolve(response)
          })
          .catch((err: any) => {
            reject(err)
          })
          .finally(() => {
            loadingController.clearLoading()
          })
      })
    }
  }

  getters: GetterTree<UserStore, any> = {
    getUsername (state: UserStore) {
      return state.username
    },
    getPhone (state: UserStore) {
      return state.phone
    },
    getName (state: UserStore) {
      return state.name
    },
    getToken (state: UserStore) {
      return state.token
    },
    getAuthorities (state: UserStore) {
      return state.authorities
    }
  }
}

export default new UserStoreModule()
