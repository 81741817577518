import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'

import home from './modules/home'
import manager from './modules/manager'
import profile from './modules/profile'

const login = () => import('@/views/login/index.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      role: []
    }
  }
]

routes.push(home, manager, profile)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const hasAuthorities = (role: Array<string>, next: () => void) => {
  let isNext = false
  if (role.length === 0) isNext = true
  role.forEach((item) => {
    if (store.getters['user/getAuthorities'].indexOf(item) !== -1) {
      isNext = true
    }
  })
  isNext ? next() : router.go(-1)
}

router.beforeEach((to, from, next) => {
  if (!store.getters['user/getUsername']) {
    store.dispatch('user/getUserInfo')
      .then((response: any) => {
        response.user === 'anonymousUser'
          ? next('/login')
          : (
              to.path === '/login'
                ? next('/home')
                : hasAuthorities(to.meta.role as Array<string>, next)
            )
      })
      .catch(() => {
        router.push('/login')
      })
  } else {
    hasAuthorities(to.meta.role as Array<string>, next)
  }
})

export default router
