import axios, { AxiosResponse } from 'axios'
import getErrorMessage from './getErrorMessage'
import store from '@/store'
import { showNotify } from 'vant'

const service = axios

service.interceptors.request.use(
  (config) => {
    return config
  },
  (err: any) => {
    return Promise.reject(err)
  }
)

service.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.status === 200 ? response : Promise.reject(response)
  },
  (err: any) => {
    return Promise.reject(err)
  }
)

export default function request (reqData: any): any {
  return new Promise((resolve, reject) => {
    const { ...tempData } = reqData
    tempData.timeoutErrorMessage = 'Network Error'

    if (store.getters['user/getToken']) {
      !tempData.headers
        ? tempData.headers = { 'X-XSRF-TOKEN': store.getters['user/getToken'] }
        : tempData.headers['X-XSRF-TOKEN'] = store.getters['user/getToken']
    }

    service.request(tempData)
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => {
        const result = getErrorMessage(err)
        if (result.type !== undefined) {
          showNotify(result)
        }
        reject(result.message)
      })
  })
}
