import http from '@/utils/http'
import { basicInterface } from './basic'

export interface authorityInterface extends basicInterface {
  username?: string
}

export class authorityHttp {
  static getUserInfo () {
    return http({
      url: '/api/users/me',
      method: 'GET'
    })
  }

  static login (username: string, password: string, rememberMe: boolean) {
    const data = new FormData()
    data.append('username', username)
    data.append('password', password)
    data.append('remember-me', rememberMe ? 'true' : 'false')
    return http({
      url: '/api/auth/login',
      method: 'POST',
      data
    })
  }

  static logout () {
    return http({
      url: '/api/auth/logout',
      method: 'POST'
    })
  }
}
